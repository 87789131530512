import React from "react";
import NavBar from "../NavBar";
import BhadrakaaliSlider from "./BhadrakaaliSlider";
const Bhadrakaali = () => {
  return (
    <div>
      <NavBar />
      <BhadrakaaliSlider />
      <h1 style={{ textAlign: "center", marginTop: "20px" }}>
        Bhadrakaali (Vetala Kantastitha Bhadrakaali)
      </h1>

      <h6
        style={{
          textAlign: "justify",
          width: "80%",
          margin: "auto",
          marginBottom: "20px",
          paddingBottom: "3rem",
        }}
      >
        Fierce and awesome, Godess Bhadrakaali is equally important as the main
        deity, and reigns in the nearby shrine. Legend has it that when Shiva’s
        Third Eye opened and Bhadrakaali emerged, he asked her to slay Darikan.
        Shiva gave her the trident(trishUla), sword(asi), mace(gadA),
        axe(parashu) as weapons to kill Darika, and she sought the help of
        Vetala (King of the Forest). When Bhadrakali beheads Darika, Vetala
        spreads his enormous fangs and drinks his blood. This Goddess Kali form
        is a powerful manifestation of Shakti. The image of the deity is seen in
        the ‘Raudra’ (angry) bhavam, filled with radiance and strength, after
        her battle with Darika. An embodiment of unassailable power, passion and
        self-control, Bhadrakaali is covered in an aura of peace that adds to
        the spiritual atmosphere of the temple. Once a year, a Desha Guruthi is
        offered to Bhadrakali, a supreme and intense form of worshipping the
        Goddess. It is meant to attract blessings for the peace and health of
        the entire desham. On a daily basis, Kaivettaka Guruthi is offered to
        Bhadrakali to destroy obstacles.
      </h6>
    </div>
  );
};

export default Bhadrakaali;
