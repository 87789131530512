import React from "react";
import NavBar from "../NavBar";
import NagaDevathasSlider from "./NagaDevathasSlider";

const NagaDevathas = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <NagaDevathasSlider />
      </div>
      <div>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>NagaDevathas</h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            marginBottom: "20px",
            paddingBottom: "3rem",
          }}
        >
          In the Southeastern corner (agnikon) of the temple premises is located
          a stunning statue of the Nagadevatha, the serpent God. Standing 9 feet
          tall and with five heads, an imposing snake figure with flaring red
          fangs, piercing eyes and yellow, coiled body has been installed.
          Nestling within this awe-inspiring form is the actual deity, a stone
          figurine. The Nagadevathas are powerful and famed in Hindu mythology.
          The serpent is found to be Lord Siva’s neck ornament, and Lord Vishnu
          rests peacefully in the coils of the serpent, in the Palazhi in
          Vaikundham. Similarly, stories abound of the benevolence and blessings
          of Nagadevathas in helping lands become fertile and preventing
          diseases. Sarpa pooja is propitious for the alleviation of ill-health,
          skin related problems, and also facilitates good luck, good progeny,
          good alliances and relationships as well as prosperity and mental
          peace. Every month, aayilya pooja is held to attract the blessings of
          Sarpa devathas. At this time, the deities are offered, milk, manjal
          podi (turmeric powder). Paalum noorum is a special preparation made of
          rice, turmeric and cow’s milk, which is dedicated to Nagaraja and
          Nagayakshi in this temple. Devotees pre-book this pooja and await to
          join in worshipping the deity every month.{" "}
        </h6>
      </div>
    </div>
  );
};

export default NagaDevathas;
