import React from "react";
import NavBar from "../NavBar";
import NanadurgaSlider from "./NanadurgaSlider";

const Nanadurga = () => {
  return (
    <div>
      <NavBar />
      <NanadurgaSlider />
      <h1 style={{ textAlign: "center", marginTop: "20px" }}>Nanadurga Devi</h1>
      <h6
        style={{
          textAlign: "justify",
          width: "80%",
          margin: "auto",
          marginBottom: "20px",
          paddingBottom: "3rem",
        }}
      >
        In the main sanctum sanctorum, the main deity, a radiant Durga Devi in
        the form of “Sree Nanadurga” resides facing the West. The goddess had
        beheaded the asura Durgaman and also destroyed Mahishasuran, due to
        which she is famed as ‘Mahishasuramardhini’. The goddess is considered
        to be the embodiment of universal energy (Shakthi). Hers is a beneficent
        presence, bountiful and beautiful, terrifying and compassionate,
        righteous and magnificent. Enveloping the main sanctum, and covering all
        sides of it are intricate teakwood carvings of six devis, seated in
        their vahanas and smiling radiantly– namely, MahatripuraSundari,
        Annapoorneshwari, Vidya Saraswathi, Mookambika Devi, Dhanalakshmi,
        Gayathri Devi. Important offerings to Nanadurga Devi include Poomoodal,
        Pournami Pooja, Mutturakkal and Pushpanjali. Offerings of flowers and
        garlands are also made.
      </h6>
    </div>
  );
};

export default Nanadurga;
