import React from "react";
import download from "../assets/img/logo/downloadLogo.png";
import vettikara from "../assets/vettikara.pdf";
import { MdDownloadForOffline } from "react-icons/md";
import css from "./DownloadPdf.module.scss";
const DownloadPdf = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
    >
      <img
        style={{ width: "170px", cursor: "pointer" }}
        src={download}
        alt=""
      />
      <div className={css.downloadButton}>
        <a href={vettikara} download="brochure">
          <MdDownloadForOffline> </MdDownloadForOffline>
        </a>
      </div>
    </div>
  );
};

export default DownloadPdf;
