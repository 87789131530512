import React from "react";
import NavBar from "../NavBar";
import MuttarukkalSlider from "./MuttarukkalSlider";
const Muttarukkal = () => {
  return (
    <div
      style={{
        paddingBottom: "3rem",
      }}
    >
      <div>
        <NavBar />
      </div>
      <div>
        <MuttarukkalSlider />
        <h1 style={{ textAlign: "center", marginTop: "20px" }}> Muttarukkal</h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            marginBottom: "20px",
          }}
        >
          Muttirakkal (or breaking of coconut) for Devi is considered to be a
          powerful offering meant to bring about solace for ailments and other
          problems in life. ‘Muttu’ refers to obstacles and “arukkal” means ‘to
          cut’ – which means to put an end to obstacles that impede life. It
          involves offering a dehusked coconut to the priest along with the name
          and star of the person, which is then broken in Devi’s presence.
          Sometimes, it is possible to give “lakshanam” or predictions based on
          the broken coconut that will help the devotee offer pujas to mitigate
          their problems. The dehusked coconut may be brought from home by the
          devotee or purchased from the temple counter.
        </h6>
      </div>
    </div>
  );
};

export default Muttarukkal;
