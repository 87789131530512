import React, { Suspense, lazy } from "react";
import "./app.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Bhadrakaali from "./components/deitiepage/Bhadrakaali";
import Ganapathy from "./components/deitiepage/Ganapathy";
import Nanadurga from "./components/deitiepage/Nanadurga";
import Saraswathy from "./components/deitiepage/Saraswathy";
import Mahishi from "./components/deitiepage/Mahishi";
import VedaVyasa from "./components/deitiepage/VedaVyasa";
import NagaDevathas from "./components/deitiepage/NagaDevathas";
import Navagrahas from "./components/deitiepage/Navagrahas";
import Muttarukkal from "./components/offeringpage/Muttarukkal";
import PhotoGallery from "./components/photo/PhotoGallery";
const Offering = lazy(() => import("./components/offeringpage/Offering"));
const Poomoodal = lazy(() => import("./components/offeringpage/Poomoodal"));
const Navagraha = lazy(() => import("./components/offeringpage/Navagraha"));
const Pournami = lazy(() => import("./components/offeringpage/Pournami"));
const Aayilyam = lazy(() => import("./components/offeringpage/Aayilyam"));
const Desha = lazy(() => import("./components/offeringpage/Desha"));
const Neeranjanam = lazy(() => import("./components/offeringpage/Neeranjanam"));
const OtherOffering = lazy(() =>
  import("./components/offeringpage/OtherOffering")
);

const TempleArchitecture = lazy(() => import("./pages/TempleArchitecture"));

const HomeOne = lazy(() => import("./home/HomeOne"));

const MainDeitiesPage = lazy(() =>
  import("./pages/maindeitiepage/MainDeitiesPage")
);

// const ProjectDetails = lazy(() => import("./project/ProjectDetails"));
// const BlogLeftSidebar = lazy(() => import("./blog/BlogLeftSidebar"));
// const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
// const BlogDetailsLeftSidebar = lazy(() =>
//   import("./blog/BlogDetailsLeftSidebar")
// );

const Contact = lazy(() => import("./pages/Contact"));
const NoMAtch = lazy(() => import("./pages/404"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/home-one" element={<HomeOne />} />
          <Route path="/gallery" element={<PhotoGallery />} />

          <Route path="/maindeitiespage" element={<MainDeitiesPage />} />
          <Route path="/nanadurga" exact element={<Nanadurga />} />
          <Route path="/ganapathy" exact element={<Ganapathy />} />
          <Route path="/saraswathy" exact element={<Saraswathy />} />
          <Route path="/bhadrakaali" element={<Bhadrakaali />} />
          <Route path="/mahishi-mokshakan-ayyappan" element={<Mahishi />} />
          <Route path="/vedaVyasa-&-brahmaRakshass" element={<VedaVyasa />} />
          <Route path="/nagaDevathas" element={<NagaDevathas />} />
          <Route path="/navagrahas" element={<Navagrahas />} />

          <Route path="/offering" element={<Offering />} />
          <Route path="/poomoodal" element={<Poomoodal />} />
          <Route
            path="/navagraha-Poojas-Otta-graha-Poojas"
            element={<Navagraha />}
          />
          <Route path="/pournami-pooja" element={<Pournami />} />
          <Route path="/aayilyam-pooja" element={<Aayilyam />} />
          <Route
            path="/desha-guruthi-&-kaivettaka-guruthi"
            element={<Desha />}
          />
          <Route path="/neeranjanam" element={<Neeranjanam />} />
          <Route
            path="/other-important-offerings-and-festivals"
            element={<OtherOffering />}
          />
          <Route path="/temple-Architecture" element={<TempleArchitecture />} />

          <Route path="/contact-us" element={<Contact />} />
          <Route path="/muttarukkal" element={<Muttarukkal />} />

          <Route path="*" element={<NoMAtch />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
