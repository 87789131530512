import React, { Component } from "react";
import logo from "../assets/img/logo/logo.png";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { AiOutlineWhatsApp } from "react-icons/ai";
import DownloadPdf from "./DownloadPdf";
import Map from "./Map";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div
          className={`header-area header-sticky header-sticky--default ${
            this.state.scroll > this.state.top ? "is-sticky" : ""
          }`}
        >
          <div className="header-area__desktop header-area__desktop--default">
            {/*      
            <div className="header-top-bar">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
      
                    <div className="top-bar-left-wrapper">
                      <div className="social-links social-links--white-topbar d-inline-block">
                        <ul>
                          <li>
                            <a href="//facebook.com">
                              <i className="zmdi zmdi-facebook" />
                            </a>
                          </li>
                          <li>
                            <a href="//twitter.com">
                              <i className="zmdi zmdi-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="//vimeo.com">
                              <i className="zmdi zmdi-vimeo" />
                            </a>
                          </li>
                          <li>
                            <a href="//linkedin.com">
                              <i className="zmdi zmdi-linkedin-box" />
                            </a>
                          </li>
                          <li>
                            <a href="//skype.com">
                              <i className="zmdi zmdi-skype" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div> */}
            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className="header-info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="header-info-wrapper align-items-center">
                      {/* logo */}

                      <div className="logo">
                        <Link to={`${process.env.PUBLIC_URL}/home-one`}>
                          <img src={logo} alt="Logo" className="img-fluid" />
                        </Link>
                      </div>
                      {/* header contact info */}
                      <div className="header-contact-info">
                        <div
                          className="header-info-single-item"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="header-info-single-item__icon">
                            <AiOutlineWhatsApp />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6
                              className="header-info-single-item__title"
                              style={{ cursor: "pointer" }}
                            >
                              8281212635
                            </h6>
                            <h6
                              className="header-info-single-item__title"
                              style={{ cursor: "pointer" }}
                            >
                              9323969516
                            </h6>
                          </div>
                        </div>
                        {/* 
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-home" />
                          </div>
                          <div className="header-info-single-item__content">
                            <h6 className="header-info-single-item__title">
                              Address
                            </h6>
                            <p className="header-info-single-item__subtitle">
                              Muncipal, Park Rd, Irinjalakuda
                            </p>
                          </div>
                        </div> */}

                        <div
                          className="header-info-single-item"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="header-info-single-item__icon">
                            <i className="zmdi zmdi-map" />
                          </div>
                          <div className="header-info-single-item__content">
                            <span className="header-info-single-item__title">
                              <Map />
                            </span>
                          </div>
                        </div>
                        <div className="header-info-single-item">
                          <div className="header-info-single-item__content">
                            {" "}
                            <DownloadPdf />
                          </div>
                        </div>
                      </div>
                      {/* mobile menu */}
                      <div
                        className="mobile-navigation-icon"
                        id="mobile-menu-trigger"
                        onClick={this.activeMobileMenu}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className="header-navigation-area default-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* header navigation */}
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav>
                          <ul>
                            <li>
                              <Link to={`${process.env.PUBLIC_URL}/`}>
                                {" "}
                                HOME{" "}
                              </Link>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link
                                to={`${process.env.PUBLIC_URL}`}
                              >
                                MAIN DEITIES
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/nanadurga`}
                                  >
                                    NANADURGA DEVI
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/bhadrakaali`}
                                  >
                                    BHADRAKAALI
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/navagrahas`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Navagrahas
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/nagaDevathas`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    NagaDevathas
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/mahishi-mokshakan-ayyappan`}
                                  >
                                    AYYAPPYAN
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/ganapathy`}
                                  >
                                    GANAPATHY
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/saraswathy`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Saraswathy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/vedaVyasa-&-brahmaRakshass`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    VedaVyasa
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link
                                to={`${process.env.PUBLIC_URL}`}
                                style={{ textTransform: "uppercase" }}
                              >
                                Offering
                              </Link>
                              <ul className="submenu">
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/poomoodal`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Poomoodal
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/navagraha-Poojas-Otta-graha-Poojas`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Navagraha Poojas
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/pournami-pooja`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Pournami Pooja
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/aayilyam-pooja`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Aayilyam Pooja
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/desha-guruthi-&-kaivettaka-guruthi`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Desha Guruthi
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/neeranjanam`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Neeranjanam
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/muttarukkal`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Muttarukkal
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/other-important-offerings-and-festivals`}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    Other Important Offerings and Festivals
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <Link
                                to={`${process.env.PUBLIC_URL}/temple-Architecture`}
                                style={{ textTransform: "uppercase" }}
                              >
                                Temple Architecture
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${process.env.PUBLIC_URL}/gallery`}
                                style={{ textTransform: "uppercase" }}
                              >
                                Gallery
                              </Link>
                            </li>
                            {/* <li>
                              <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                                CONTACT
                              </Link>{" "}
                            </li> */}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}

        {/* Mobile Menu */}
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
