import React, { Component } from "react";
import SwiperSlider, { SwiperSlide } from "../swiper";
import slider1 from "../../assets/img/gana/all.jpg";
import slider2 from "../../assets/img/gana/ganapathy.jpg";
import slider3 from "../../assets/img/gana/ganapathy2.jpg";
import slider4 from "../../assets/img/gana/ganapathy3.jpg";

class GanapathySlider extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      navigation: true,
      autoplay: {
        delay: 5500,
        disableOnInteraction: false,
      },
    };
    let data = [
      {
        bgImg: slider1,
        btnLink: "contact-us",
      },
      {
        bgImg: slider2,
        btnLink: "contact-us",
      },
      {
        bgImg: slider3,
        btnLink: "contact-us",
      },
      {
        bgImg: slider4,
        btnLink: "contact-us",
      },
    ];

    let DataList = data.map((val, i) => {
      return (
        <SwiperSlide key={i}>
          <div
            className="hero-slider__single-item"
            style={{
              backgroundImage: `url(${val.bgImg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="hero-slider__content-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="hero-slider__content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });

    return (
      <div>
        {/*====================  hero slider area ====================*/}
        <div className="hero-alider-area">
          <SwiperSlider options={params}>{DataList}</SwiperSlider>
        </div>
        {/*====================  End of hero slider area  ====================*/}
      </div>
    );
  }
}

export default GanapathySlider;
