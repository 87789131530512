import React from "react";
import NavBar from "../NavBar";
import GanapathySlider from "./GanapathySlider";

const Ganapathy = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <GanapathySlider />
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>Ganapathy</h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            marginBottom: "20px",
            paddingBottom: "3rem",
          }}
        >
          ithin the inner premises of the temple and at the Southwest corner
          (Kannimoola), sits Lord Ganapathy. The Kannimoola direction is of
          special Vastu significance, providing protection for the temple. The
          Kannimolla Ganapathy also has another significance – that of being
          Vighneshawara, or the remover of all obstacles. This Ganapathy is also
          revered as the protector and Rakshaka of his devotees. Kannimoola
          Ganapathy is one of the deities in Sabarimala too. In this peaceful
          corner, sits Lord Ganapathy, atop his Vahana, the mouse.
        </h6>
      </div>

      <div></div>
    </div>
  );
};

export default Ganapathy;
