import React from "react";
import NavBar from "../NavBar";
import MahishiSlider from "./MahishiSlider";

const Mahishi = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <MahishiSlider />
      </div>
      <div>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
          Mahishi-Mokshakan Ayyappan
        </h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            marginBottom: "20px",
            paddingBottom: "3rem",
          }}
        >
          In the south-west corner of the temple complex resides Sri Ayyappan,
          in a separate shrine. Within the shrine, on the side of Sri
          Ayyappaswamy, Malikappurathamma Devi, who achieved salvation by
          Mahishi Nigraha, flourishes with her arms folded. Puli, the vehicle,
          is also located nearby. Sesame seed oil lamps are lit, and Neeranjanam
          is performed to remove Shanidoshas and for blessings. For 41 days
          during the mandala period, lamps and garlands are lit in this
          Sannidhanam and on the concluding day, the tenth day, Ashtabhishekam
          and special pujas are performed for Sri Ayyappan in the morning,
          followed by Puspabhishekam with special flowers in the evening.
        </h6>
      </div>
    </div>
  );
};

export default Mahishi;
