import React from "react";
import NavBar from "../NavBar";
import NavagrahasSlider from "./NavagrahasSlider";

const Navagrahas = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <NavagrahasSlider />
      </div>
      <div>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>Navagrahas</h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            marginBottom: "20px",
            paddingBottom: "3rem",
          }}
        >
          In alignment with Vastu principles, the Navagrahas are situated in the
          north-east part of the temple premise. The Navagrahas represent the
          nine heavenly bodies and wield immense influence over the lives of
          individuals. The Navagraha deities in this temple are placed in the
          form of a square with the Sun in the centre, and the planets around it
          – as seen in nature. The Navagrahas are placed in a manner that it is
          possible for devotees to circumambulate them as they offer prayers.
          Since ancient times, the influence of Navagrahas on personal life and
          the resulting ups and downs experienced in daily life have been known.
          Astrology is the science that uses the position of the 9 planets in
          the twelve signs at the time of birth to predict the course of events
          that a person may experience in life. According to the position and
          strength of each planet, there are different yogas. There are many
          yogas like Panchamaha yoga, Raja yoga, Shakada yoga, Kemadruma yoga,
          Kesari yoga, Pasha yoga. There are also many conflicts and struggles
          that an individual is exposed to, depending on the planetary
          alignments and influences at a particular time of their life. In order
          to propitiate the planets, and tone down any harmful effects of
          inauspicious planetary alignments, performing navagraha poojas, Shani
          Pooja, Rahu Pooja, Ketu Pooja, Aditya Pooja, Kuchashanthi Pooja, Budha
          Pooja are very important. In case of strong inauspicious influences,
          navagraha homams are also performed to remove impediments, attract
          peace, health and prosperity.
        </h6>
      </div>
    </div>
  );
};

export default Navagrahas;
