import React from "react";
import NavBar from "../NavBar";
import VedaVyasaSlider from "./VedaVyasaSlider";

const VedaVyasa = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <VedaVyasaSlider />
      </div>
      <div style={{ height: "fit-content", paddingBottom: "15px" }}>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
          VedaVyasa & BrahmaRakshass{" "}
        </h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            paddingBottom: "3rem",
            marginBottom: "20px",
          }}
        >
          Outside the temple sits a life-sized form of the sage Vyasa, the Guru
          and fountain of wisdom. Just as Saraswathi Pooja is conducted to
          attract the blessings to become proficient in academic pursuits, so
          also Veda Vyasa pooja is meant to bring blessings of success to
          academic endeavours.
        </h6>
      </div>
    </div>
  );
};

export default VedaVyasa;
