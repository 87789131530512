import React from "react";
import css from "./PhotoGallery.module.scss";
import NavBar from "../NavBar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import img1 from "../../assets/img/gallery/1.jpg";
import img2 from "../../assets/img/gallery/2.jpg";
import img3 from "../../assets/img/gallery/3.jpg";
import img4 from "../../assets/img/gallery/4.jpg";
import img5 from "../../assets/img/gallery/5.jpg";
import img6 from "../../assets/img/gallery/6.jpg";
import img7 from "../../assets/img/gallery/7.jpg";
import img8 from "../../assets/img/gallery/8.jpg";
import img9 from "../../assets/img/gallery/9.jpg";
import img10 from "../../assets/img/gallery/10.jpg";
import img11 from "../../assets/img/gallery/11.jpg";
import img12 from "../../assets/img/archi/1.jpg";
import img13 from "../../assets/img/archi/2.jpg";
import img14 from "../../assets/img/archi/3.jpg";
import img15 from "../../assets/img/archi/4.jpg";
import img16 from "../../assets/img/archi/5.jpg";
import img17 from "../../assets/img/archi/6.jpg";
import img18 from "../../assets/img/archi/7.jpg";
import img19 from "../../assets/img/archi/8.jpg";
import img20 from "../../assets/img/gallery/12.jpg";
import img21 from "../../assets/img/gallery/13.jpg";
import img22 from "../../assets/img/gallery/14.jpg";
import img23 from "../../assets/img/gallery/15.jpg";
import img24 from "../../assets/img/gallery/16.jpg";
import img25 from "../../assets/img/offering/naagam1.jpg";
import img26 from "../../assets/img/deities/Bhadrakaali.jpg";
import img27 from "../../assets/img/deities/Bhramarakshas.jpg";
import img28 from "../../assets/img/deities/Ganapathy.jpg";
import img29 from "../../assets/img/deities/Mahishi.jpg";

export default function PhotoGallery() {
  return (
    <div className={css.wrapper}>
      <div className={css.navbar}>
        <NavBar />
      </div>
      <div className={css.body}>
        <ImageList sx={{ width: 900, height: 850, padding: "1rem" }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar title={item.title} position="below" />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
}
const itemData = [
  {
    img: img1,
    title: "Bhadrakaali",
  },
  {
    img: img2,
    title: "Bhadrakaali",
  },
  {
    img: img3,
    title: "DurgaDevi",
  },
  {
    img: img4,
    title: "DurgaDevi",
  },
  {
    img: img5,
    title: "DurgaDevi",
  },
  {
    img: img6,
    title: "DurgaDevi",
  },
  {
    img: img7,
    title: "",
  },
  {
    img: img8,
    title: "",
  },
  {
    img: img9,
    title: "",
  },
  {
    img: img10,
    title: "",
  },
  {
    img: img11,
    title: "",
  },
  {
    img: img12,
    title: "",
  },
  {
    img: img13,
    title: "",
  },
  {
    img: img14,
    title: "",
  },
  {
    img: img15,
    title: "",
  },
  {
    img: img16,
    title: "",
  },
  {
    img: img17,
    title: "",
  },
  {
    img: img18,
    title: "",
  },
  {
    img: img19,
    title: "",
  },
  {
    img: img20,
    title: "",
  },
  {
    img: img21,
    title: "",
  },
  {
    img: img22,
    title: "",
  },
  {
    img: img23,
    title: "",
  },
  {
    img: img24,
    title: "",
  },
  {
    img: img25,
    title: "",
  },
  {
    img: img26,
    title: "",
  },
  {
    img: img27,
    title: "",
  },
  {
    img: img28,
    title: "",
  },
  {
    img: img29,
    title: "",
  },
];
