import React from "react";
import NavBar from "../NavBar";
import SaraswathySlider from "./SaraswathySlider";

const Saraswathy = () => {
  return (
    <div>
      <div>
        <NavBar />
      </div>
      <div>
        <SaraswathySlider />
      </div>
      <div>
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
          Saraswathy Mandapam
        </h1>
        <h6
          style={{
            textAlign: "justify",
            width: "80%",
            margin: "auto",
            paddingBottom: "3rem",
            marginBottom: "20px",
          }}
        >
          On the left hand side of the main temple is the Saraswathy Mandapam.
          In it, sits a magnificent and beatifically radiant Durga form in Teak
          wood, 7.5 feet tall, Goddess Durga reigns with a smiling visage,
          sitting atop a lion, and nestled within a golden lotus flower, radiant
          and resplendent in her jewels, with a Vyali sculpted overhead,
          blessing all who offer their prayers. Within the Saraswathy Mandapam
          is a statue of “Jnana Dakshinamoorthy”, who is a the personification
          of Lord Sri Parameshwara as the primeval Guru. As he sat under the
          cooling shade of a banyan tree and offered the lessons of yoga, music,
          knowledge of the Shastras and wisdom to maharshis (sages), he has his
          right foot placed on the head of Apasmara, or ignorance. In his arms
          he holds symbols of knowledge and wisdom, and the mudra of the right
          hand depicts Jnana Mudra or knowledge. Dakshinamurthy’s form is
          powerful, brimming with wisdom and joy, an epitome of the blissful
          state of yogic meditation. In this way, he became synonymous with the
          ultimate Guru. Hence, it is believed that those who do not have a Guru
          may pray to Lord Dakshinamoorthy and receive blessings of their Guru.
          Adjacent to this is a form of Vidyagopala Krishnan, smiling fondly,
          holding his flute in hand. The Saraswathi Mandapam here has played
          host to many programs, arangettams (initiation into the stage program)
          of many artistes. Every year, Navarathri Pooja happens in this
          mandapam, and books are kept for pooja here. Children are also
          initiated into academic pursuits in this mandapam, with the blessings
          of these deities through vidyarambham, where they are made to write
          for the first time.
        </h6>
      </div>
    </div>
  );
};

export default Saraswathy;
