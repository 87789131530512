import React from "react";
import css from "./Map.module.scss";
const Map = () => {
  const openGoogleMap = () => {
    const mapUrl =
      "https://www.google.com/maps/place/Vettikkara+Nanadurga+Navagraha+Temple+%23Trichur+%23Thrisshur+%23Shani+Temple+%23Rahupooja+%23irinjalakuda/@10.3443372,76.2114262,1088m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3ba7f6463caae449:0x19d6816b6f4dc7ed!2sVettikkara+Nanadurga+Navagraha+Temple+%23Trichur+%23Thrisshur+%23Shani+Temple+%23Rahupooja+%23irinjalakuda!8m2!3d10.3443319!4d76.2136149!16s%2Fg%2F11c6jfgmx3!3m5!1s0x3ba7f6463caae449:0x19d6816b6f4dc7ed!8m2!3d10.3443319!4d76.2136149!16s%2Fg%2F11c6jfgmx3!5m2!1e4!1e2"; // URL for Google Maps
    window.open(mapUrl, "_blank");
  };
  return (
    <div className={css.wrapper}>
      <div className={css.map}>
        <p
          onClick={openGoogleMap}
          className="header-info-single-item__subtitle"
          style={{ cursor: "pointer" }}
        >
          Find on map
        </p>
      </div>
    </div>
  );
};

export default Map;
